import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "exercise-hug-a-tree"
    }}><a parentName="h2" {...{
        "href": "/lessons/hug-a-tree/"
      }}>{`Exercise: Hug a Tree`}</a></h2>
    <p>{`As you begin to practice `}<em parentName="p">{`Hug a Tree`}</em>{`, pay attention to the feeling of your head floating upward, as everything else in your body releases downward, stretching your spine.`}</p>
    <p>{`This feeling of settling down into your legs, in a relaxed and centered manner, is something you want to continue to feel as you practice the Tai Chi postures and form.`}</p>
    <p>{`The goal is to learn to stand (and later to move) with a still mind, an upright posture, and supple joints.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons/hug-a-tree"
      }}>{`Learn how to Hug a Tree…`}</a></p>
    <h2 {...{
      "id": "exercise-sitting-left-sitting-right"
    }}><a parentName="h2" {...{
        "href": "/lessons/shifting-weight/"
      }}>{`Exercise: Sitting Left, Sitting Right`}</a></h2>
    <p>{`Practice shifting your weight from left to right and settling the weight down into the feet.`}</p>
    <h2 {...{
      "id": "tai-chi-posture-opening"
    }}>{`Tai Chi Posture: Opening`}</h2>
    <p>{`Stand like Hug a Tree, but lifting the arms up and pressing back down.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons"
      }}>{`Back to Lessons`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-2/"
      }}>{`On to Lesson 2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      